<template>
  <router-view/>
</template>
<script>
export default {
  name: 'tutleasIndex'
}
</script>

<style scoped>

</style>
